<template>
	<div style="display:inline-block">
		<ul class="pagination pagination-danger" v-if="pagination">
						
			<li v-if="Number(pagination.current_page) != 1" class="page-item" :class="{active:Number(pagination.current_page) != 1, disabled: Number(pagination.current_page) == 1}">
				<a href="javascript:void(0);" class="page-link" @click="fetchPrevious"> prev</a>
			</li>
			
			<li v-if="Number(pagination.current_page) != Number(pagination.last_page)" class="page-item" :class="{active:Number(pagination.current_page) != Number(pagination.last_page), disabled: Number(pagination.current_page) == Number(pagination.last_page)}">
				<a href="javascript:void(0);" class="page-link" @click="fetchNext">next </a>
			</li>
		</ul>
	</div>
</template>
<script>
	export default{
		name: "SimplePagination",
		data(){
			return {
				
			}
        },
        props: ['pagination', 'callback'],
		methods: {
			fetchNext(){
				if (Number(this.pagination.current_page) != Number(this.pagination.last_page)){
				
					// let url = new URL(this.pagination.next_page_url);
					// let params = new URLSearchParams(url.search)
                    
                    this.callback(Number(this.pagination.current_page)+1);
				}
				
			},
			fetchPrevious(){
				if (Number(this.pagination.prev_page_url) != 1){
				
					// let url = new URL(this.pagination.prev_page_url);
                    // let params = new URLSearchParams(url.search);
                    this.callback(Number(this.pagination.current_page)-1);
				}
			}
		},
		created(){
			
		}
	}
</script>
<style scoped>
.page-item.active .page-link {
	z-index:0;
}

a.page-link:hover{
    color: black !important;
    text-decoration: none;
}
</style>
