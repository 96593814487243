<template>
    <div id="view-admin-activity-list" class="content modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="min-width:90%">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel2">Admin Activity</h4>
                    <button ref="close_button" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body text-left">
                    <div class="box row bg-pale-secondary box-outline-dark">
                        <div class="box-body col-12">
                            <div class="d-flex justify-content-start">
                                <div class="form-group ml-0 mr-2">
                                    <div class="controls">
                                        <label>From</label>
                                        <input v-model="from_date" type="date" class="form-control"/>
                                    </div>
                                </div>
                                <div class="form-group ml-0 mr-2">
                                    <div class="controls">
                                        <label>To</label>
                                        <input v-model="to_date" type="date" class="form-control"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="d-flex">
                                    <div class="form-group">
                                        <button @click="onSearch" class="btn btn-primary">Search Logs</button>
                                    </div>&nbsp;&nbsp;
                                    <div class="form-group">
                                        <button @click="clearSearch" class="btn btn-outline btn-warning">Clear Search</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div v-if="loading" class="spinner-border spinner text-success text-center" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div v-if="activityList" class="row">
                        <ul v-for="(history, day) in activityList" class="timeline" :key="day">
                            <!-- timeline time label -->
                            <li class="time-label">
                                <span class="bg-info">
                                    {{ day }}
                                </span>
                            </li>
                            <!-- /.timeline-label -->
                            <!-- timeline item -->
                            <!-- <li>
                                <i class="ion ion-email bg-blue"></i>
                                <div class="timeline-item">
                                    <span class="time"><i class="fa fa-clock-o"></i> 11:48</span>

                                    <h3 class="timeline-header"><a href="#">Genelia</a> sent you an email</h3>
                                    <div class="timeline-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ...
                                    </div>
                                </div>
                            </li> -->
                            <!-- END timeline item -->
                            <!-- timeline item -->
                            <li v-for="story in history" :key="story.id">
                                <i class="ion ion-person bg-green"></i>
                                <div class="timeline-item">
                                    <span class="time"><i class="fa fa-clock-o"></i> {{ story.time_diff }}</span>

                                    <h3 class="timeline-header no-border"><a href="#">{{ story.user.account_type=='individual' ? `${story.user.first_name} ${story.user.last_name}` : story.useer.business_name }}</a> {{ story.description }}</h3>
                                </div>
                            </li>
                            <!-- END timeline item -->
                            <!-- timeline item -->
                            <!-- <li>
                                <i class="ion ion-chatbubble-working bg-purple"></i>

                                <div class="timeline-item">
                                    <span class="time"><i class="fa fa-clock-o"></i> 55 mins ago</span>

                                    <h3 class="timeline-header"><a href="#">Jone Doe</a> commented on your post</h3>

                                    <div class="timeline-body">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus numquam facilis enim eaque, tenetur nam id qui vel velit similique nihil iure molestias aliquam, voluptatem totam quaerat, magni commodi quisquam.
                                    </div>
                                    <div class="timeline-footer text-right">
                                        <a class="btn bg-purple btn-flat btn-sm">View comment</a>
                                    </div>
                                </div>
                            </li> -->
                            <!-- END timeline item -->
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <CustomPagination :pagination="metaData" :callback="searchLogs"/>
                    <!-- <button type="button" class="btn btn-info" @click="saveNewCustomer">Save<span v-if="loading" class="spinner-border text-white"></span></button> -->
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>
<script>
import { repository } from '../../presenter';
import CustomPagination from '../CustomPagination'
export default {
    components:{
        CustomPagination,
    },
    props: ['adminActivityList', 'listMetaData'],
    created() {
        if (this.adminActivityList){
            this.activityList = this.adminActivityList;
            this.metaData = this.listMetaData
        } 
    },
    data(){
        return{
            from_date: '',
            to_date: '',
            activityList: null,
            metaData: null,
            loading: false,
        }
    },
    methods: {
        onSearch(){
            this.searchLogs();
        },
        clearSearch(){
            this.from_date='';
            this.to_date='';
            this.searchLogs();
        },
        async searchLogs(page=1) {
            this.loading=true;
            const data = {
                from_date: this.from_date,
                to_date: this.to_date,
            }
            const response = await repository.user.searchActivityLogs(data, page)
            this.loading=false;
            if (response.success) {
                this.activityList = response.data.timeline.data;
                this.metaData = response.data.timeline.extra_data;
            }
        },
    }
}
</script>

