<template>
    <section>
        <!-- Content Wrapper. Contains page content -->
            <div class="content-wrapper">

                <!-- Main content -->
                <section class="content">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="box">
                                    <div class="box-header with-border">
                                        <h4 class="box-title">Statistics</h4>
                                    </div>
                                    <div class="box-body">
                                        <div class="row">
                                            <div class="col-xl-4 col-12">
                                                <h5 class="box-title">Total Orders: <span id="total-orders"></span></h5>
                                                <div id="order-chart"></div>
                                            </div>
                                            <div class="col-xl-4 col-12">
                                                <h5 class="box-title">Total Customers: <span id="total-customers"></span></h5>
                                                <div id="subscription-chart"></div>
                                            </div>
                                            <div class="col-xl-4 col-12">
                                                <h5 class="box-title">Total Customers: <span id="total-customers2"></span></h5>
                                                <div id="active-customer-chart"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <!--
                                <div class="box">
                                    <div class="row no-gutters">
                                        <div class="col-12 col-lg-4">
                                            <div class="box-body">
                                                <div class="d-flex">
                                                    <p class="mb-0 text-muted font-weight-bold">Monthly Visits</p>
                                                    <a class="mb-0 ml-auto font-weight-bold" href="#"><i class="ti ti-more-alt"></i> </a>
                                                </div>
                                                <div class="d-flex h-100 align-items-center justify-content-between">
                                                    <div id="dash1"></div>
                                                    <div class="mt-3 mt-sm-0 ml-sm-auto text-center text-right">
                                                        <h3 class="mb-0"><i class="icon-arrow-up-circle"></i> 4125</h3>
                                                        <p>Visitor</p>
                                                    </div>
                                                </div>
                                                <div class="progress progress-xxs mt-10 mb-0">
                                                <div class="progress-bar" role="progressbar" style="width: 35%; height: 4px;" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-4">
                                            <div class="box-body">
                                                <div class="d-flex">
                                                    <p class="mb-0 text-muted font-weight-bold">Monthly Income</p>
                                                    <a class="mb-0 ml-auto font-weight-bold" href="#"><i class="ti ti-more-alt"></i> </a>
                                                </div>
                                                <div class="d-flex h-100 align-items-center justify-content-between">
                                                    <div id="dash2"></div>
                                                    <div class="mt-3 mt-sm-0 ml-sm-auto text-center text-right">
                                                        <h3 class="mb-0"><i class="icon-arrow-up-circle"></i> $42358</h3>
                                                        <p>Income</p>
                                                    </div>
                                                </div>
                                                <div class="progress progress-xxs mt-10 mb-0">
                                                <div class="progress-bar bg-info" role="progressbar" style="width: 55%; height: 4px;" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-4">
                                            <div class="box-body">
                                                <div class="d-flex">
                                                    <p class="mb-0 text-muted font-weight-bold">Monthly Sales</p>
                                                    <a class="mb-0 ml-auto font-weight-bold" href="#"><i class="ti ti-more-alt"></i> </a>
                                                </div>
                                                <div class="d-flex h-100 align-items-center justify-content-between">
                                                    <div id="dash3"></div>
                                                    <div class="mt-3 mt-sm-0 ml-sm-auto text-center text-right">
                                                        <h3 class="mb-0"><i class="icon-arrow-up-circle"></i> 8452</h3>
                                                        <p>Sales</p>
                                                    </div>
                                                </div>
                                                <div class="progress progress-xxs mt-10 mb-0">
                                                <div class="progress-bar bg-warning" role="progressbar" style="width: 65%; height: 4px;" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-md-6 col-12">
                                <div class="row">
                                    <div v-if="isRoleAllowedCustomerWalletBalance" class="col-xl-6 col-md-8 col-12">
                                        <div class="flexbox flex-justified text-center bg-white mb-30">
                                            <div class="no-shrink py-30">
                                                <span class="ion ion-arrow-graph-up-right font-size-50 text-success"></span>
                                            </div>

                                            <div class="py-30 bg-gray-light">
                                                <div class="font-size-30" id="wallet-balance">0</div>
                                                <span>Total Customer Wallet Balance</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="isRoleAllowedAmountUnpaid" class="col-xl-6 col-md-8 col-12">
                                        <div class="flexbox flex-justified text-center bg-white mb-30">
                                            <div class="no-shrink py-30">
                                                <span class="ion ion-arrow-graph-up-right font-size-50 text-success"></span>
                                            </div>

                                            <div class="py-30 bg-gray-light">
                                                <div class="font-size-30" id="unpaid-balance">0</div>
                                                <span>Amount Unpaid</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-8 col-12">
                                        <div class="flexbox flex-justified text-center bg-white mb-30">
                                            <div class="no-shrink py-30">
                                                <span class="ion ion-social-dribbble-outline font-size-50" style="color: #ea4c89"></span>
                                            </div>

                                            <div class="py-30 bg-gray-light">
                                                <div class="font-size-30" id="total-admins">0</div>
                                                <span>Total Admins</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-8 col-12">
                                        <div class="flexbox flex-justified text-center bg-white mb-30">
                                            <div class="no-shrink py-30">
                                                <span class="ion ion-social-dribbble-outline font-size-50" style="color: #ea4c89"></span>
                                            </div>

                                            <div class="py-30 bg-gray-light">
                                                <div class="font-size-30" id="total-admins">0</div>
                                                <span>Total Admins</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="flexbox flex-justified bg-white mb-30">
                                            <div v-if="logList && logList.length>0" class="box">
                                                <div class="box-header with-border">
                                                    <h5 class="box-title">Admin activity</h5>
                                                </div>
                                                <div class="box-body p-0">
                                                    <div class="media-list media-list-hover media-list-divided">
                                                        <div v-for="(a, index) in logList.length > 5 ? 5 : logList.length" class="media" :key="index">
                                                            <div class="media-body">
                                                                <p>
                                                                    <a class="hover-primary" href="#"><strong>{{ logList[index].user ? logList[index].user.name : '' }}</strong></a>
                                                                    <time class="float-right" :datetime="logList[index].created_at">{{ logList[index].time_diff }}</time>
                                                                </p>
                                                                <p>{{ logList[index].description }}</p>
                                                            </div>
                                                        </div>
                                                        <div v-if="activityList" class="text-center bt-1 border-light p-2">
                                                            <a class="text-uppercase d-block font-size-12" href="#" data-toggle="modal" data-target="#view-admin-activity-list">See more</a>
                                                            <AdminActivityList :adminActivityList="activityList" :listMetaData="metaData"/>
                                                        </div>  
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            

                            
                            <!-- <div class="col-xl-3 col-md-6 col-12">
                                <div class="flexbox flex-justified text-center bg-white mb-30">
                                    <div class="no-shrink py-30">
                                        <span class="ion ion-social-facebook font-size-50" style="color: #3b5998"></span>
                                    </div>

                                    <div class="py-30 bg-gray-light">
                                        <div class="font-size-30">+250</div>
                                        <span>Likes</span>
                                    </div>
                                </div>
                            </div> -->
                            
                            
                            <!-- <div class="col-xl-3 col-md-6 col-12">
                                <div class="flexbox flex-justified text-center bg-white mb-30">
                                <div class="no-shrink py-30">
                                    <span class="ion ion-social-twitter-outline font-size-50" style="color: #00aced"></span>
                                </div>

                                <div class="py-30 bg-gray-light">
                                    <div class="font-size-30">+180</div>
                                    <span>Followers</span>
                                </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="box">
                                    <div class="box-header with-border">
                                        <h4 class="box-title">City Delivery Performance</h4>
                                    </div>
                                    <div class="box-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <h5 class="box-title">Pickup</h5>
                                                <div id="city-pickup-region-chart" style="height: 400px;"></div>
                                            </div>
                                            <div class="col-6">
                                                <h5 class="box-title">Delivery</h5>
                                                <div id="city-delivery-region-chart" style="height: 400px;"></div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>


                            <div class="col-12">
                                <div class="box">
                                    <div class="box-header with-border">
                                        <h4 class="box-title">State Delivery Performance</h4>
                                    </div>
                                    <div class="box-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <h5 class="box-title">Pickup</h5>
                                                <div id="state-pickup-region-chart" style="height: 400px;"></div>
                                            </div>
                                            <div class="col-6">
                                                <h5 class="box-title">Delivery</h5>
                                                <div id="state-delivery-region-chart" style="height: 400px;"></div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>




                            <div class="col-12">
                                <div class="box">
                                    <div class="box-header with-border">
                                        <h4 class="box-title">Country Delivery Performance</h4>
                                    </div>
                                    <div class="box-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <h5 class="box-title">Pickup</h5>
                                                <div id="country-pickup-region-chart" style="height: 400px;"></div>
                                            </div>
                                            <div class="col-6">
                                                <h5 class="box-title">Delivery</h5>
                                                <div id="country-delivery-region-chart" style="height: 400px;"></div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                            <!--
                            <div class="col-xl-7 col-12">
                                <div class="box">
                                <div class="box-header with-border">
                                    <h4 class="box-title">Revenue Analysis </h4>
                                    <ul class="box-controls pull-right">
                                    <li><a class="box-btn-close" href="#"></a></li>
                                    <li><a class="box-btn-slide" href="#"></a></li>
                                    <li><a class="box-btn-fullscreen" href="#"></a></li>
                                    </ul>
                                </div>
                                <div class="box-body">
                                    <div id="revenueanalysis"> </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-12 col-xl-5">
                                <div class="box">
                                    <div class="box-header with-border">
                                        <h4 class="box-title">Taxes info</h4>
                                        <ul class="box-controls pull-right">
                                        <li><a class="box-btn-close" href="#"></a></li>
                                        <li><a class="box-btn-slide" href="#"></a></li>
                                        <li><a class="box-btn-fullscreen" href="#"></a></li>
                                        </ul>
                                    </div>
                                    <div class="box-body">
                                        <div class="text-left">
                                            <ul class="flexbox flex-justified text-center">
                                                <li class="px-10">
                                                    <h6 class="mb-0 text-bold">8952</h6>
                                                    <small>Dubai</small>
                                                </li>

                                                <li class="br-1 bl-1 px-10">
                                                    <h6 class="mb-0 text-bold">7458</h6>
                                                    <small>Miami</small>
                                                </li>

                                                <li class="px-10">
                                                    <h6 class="mb-0 text-bold">3254</h6>
                                                    <small>London</small>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="box-body p-0 overflow-h">
                                        <div id="spark1"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="box">

                                    <div class="row no-gutters">
                                        <div class="col-md-8">
                                            <div class="box-body">
                                                <h4><a href="#">Sample Listing Blog Post</a></h4>
                                                <p class="time">October 16, 2017</p>

                                                <p>Holisticly maximize team building ROI via next-generation resources. Enthusiastically promote team driven customer service and error-free solutions. Dynamically myocardinate vertical leadership for synergistic "outside the box" thinking. Efficiently extend global.</p>

                                                <div class="flexbox align-items-center mt-3">
                                                    <a class="btn btn-primary" href="#">Read more</a>

                                                    <div class="gap-items-4">
                                                        <a class="text-muted" href="#">
                                                            <i class="fa fa-heart mr-1"></i> 12
                                                        </a>
                                                        <a class="text-muted" href="#">
                                                            <i class="fa fa-comment mr-1"></i> 3
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-4 bg-img d-none d-md-block" style="background-image: url(/assets/images/gallery/thumb/13.jpg)"></div>
                                    </div>
                                </div>
                            </div>
                            -->
                        </div>
                    </div>
                </section>
                <!-- /.content -->
            </div>
            <!-- /.content-wrapper -->
    </section>
</template>

<script>
import { repository } from '../presenter';
import AdminActivityList from '../components/Admin/AdminActivityList'
export default {
    components: {
        AdminActivityList,
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            activityList: null,
            logList: null,
            metaData: null,
        }
    },
    created() {
        this.searchLogs();
    },
    computed: {
        isRoleAllowedCustomerWalletBalance(){
            if (repository.adminIsRider()){
                return false;
            }
            return true;
        },
        isRoleAllowedAmountUnpaid(){
            if (repository.adminIsRider()){
                return false;
            }
            return true;
        },
    },
    mounted () {
        const irregularData = document.createElement('script')
        irregularData.setAttribute('src', `/assets/styles/vendor_components/apexcharts-bundle/irregular-data-series.js`)
        document.head.appendChild(irregularData)
        irregularData.onload = function(){
            const apex = document.createElement('script')
            apex.setAttribute('src', `/assets/styles/vendor_components/apexcharts-bundle/dist/apexcharts.js`)
            document.head.appendChild(apex)
            apex.onload = function(){
                const dashboardJS = document.createElement('script')
                dashboardJS.setAttribute('src', `/assets/js/pages/dashboard.js`)
                document.head.appendChild(dashboardJS)
            }
        }
        
    },
    methods: {
        async searchLogs() {
            console.log("inside search logs")
            const data = {from_date:'', to_date:''};
            const response = await repository.user.searchActivityLogs(data)
            console.log("response is  ", response)
            if (response.success) {
                this.activityList = response.data.timeline.data;
                this.logList = response.data.logs.data;
                this.metaData = response.data.logs.extra_data;
                console.log("logList is ", this.logList);
            }
        },
    }
}
</script>

